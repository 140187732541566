import React, { useState } from 'react';

// Simple SVG icons
const Icons = {
  ChevronDown: () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M6 9l6 6 6-6"/>
    </svg>
  ),
  ChevronUp: () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M18 15l-6-6-6 6"/>
    </svg>
  ),
  Globe: () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <circle cx="12" cy="12" r="10"/>
      <path d="M2 12h20M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/>
    </svg>
  ),
  Database: () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <ellipse cx="12" cy="5" rx="9" ry="3"/>
      <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"/>
      <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"/>
    </svg>
  ),
  Server: () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <rect x="2" y="2" width="20" height="8" rx="2" ry="2"/>
      <rect x="2" y="14" width="20" height="8" rx="2" ry="2"/>
      <line x1="6" y1="6" x2="6.01" y2="6"/>
      <line x1="6" y1="18" x2="6.01" y2="18"/>
    </svg>
  ),
  Trash: () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
    </svg>
  )
};

const ServiceCard = ({ service, baseUrl }) => {
  const [expanded, setExpanded] = useState(false);

  const getServiceIcon = (serviceName) => {
    if (serviceName.includes('apache')) return <Icons.Globe />;
    if (serviceName.includes('mysql')) return <Icons.Database />;
    if (serviceName.includes('php') || serviceName.includes('ftp')) return <Icons.Server />;
    return null;
  };

  const getServiceUrl = (service) => {
    const ports = service.ports || [];
    if (ports.length === 0) return null;

    // Determine service type and generate appropriate URL
    if (service.service_name.includes('webserver')) {
      return `http://${baseUrl}:${ports[0].external}`;
    }
    if (service.service_name.includes('phpmyadmin')) {
      return `http://${baseUrl}:${ports[0].external}`;
    }
    if (service.service_name.includes('mysql')) {
      return `mysql://${baseUrl}:${ports[0].external}`;
    }
    if (service.service_name.includes('ftp')) {
      const ftpPort = ports.find(p => p.internal === 21);
      const passivePorts = ports.filter(p => p.internal >= 30000);
      return {
        main: `ftp://${baseUrl}:${ftpPort?.external || 'N/A'}`,
        passive: passivePorts.map(p => `${baseUrl}:${p.external}`).join(', ')
      };
    }
    return null;
  };

  const serviceUrl = getServiceUrl(service);

  return (
    <div className="border rounded-lg bg-white shadow-sm mb-2">
      <div 
        className="p-4 flex items-center justify-between cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex items-center space-x-3">
          {getServiceIcon(service.service_name)}
          <div>
            <h4 className="font-medium">{service.container_name}</h4>
            <p className="text-sm text-gray-600">{service.image}</p>
          </div>
        </div>
        {expanded ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
      </div>

      {expanded && (
        <div className="px-4 pb-4 border-t">
          {serviceUrl && (
            <div className="mt-2">
              <h5 className="font-medium mb-1">Access URLs:</h5>
              {typeof serviceUrl === 'string' ? (
                <a 
                  href={serviceUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800"
                >
                  {serviceUrl}
                </a>
              ) : serviceUrl.main && (
                <div>
                  <p><span className="font-medium">Main:</span> {serviceUrl.main}</p>
                  {serviceUrl.passive && (
                    <p className="mt-1">
                      <span className="font-medium">Passive ports:</span> {serviceUrl.passive}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          
          {service.environment && Object.keys(service.environment).length > 0 && (
            <div className="mt-3">
              <h5 className="font-medium mb-1">Environment Variables:</h5>
              <div className="bg-gray-50 p-2 rounded text-sm">
                {Object.entries(service.environment).map(([key, value]) => (
                  <div key={key} className="mb-1">
                    <span className="font-medium">{key}:</span> {value}
                  </div>
                ))}
              </div>
            </div>
          )}

          {service.ports && (
            <div className="mt-3">
              <h5 className="font-medium mb-1">Ports:</h5>
              <div className="bg-gray-50 p-2 rounded text-sm">
                {service.ports.map((port, index) => (
                  <div key={index} className="mb-1">
                    <span className="font-medium">
                      {port.internal}/{port.protocol}
                    </span>
                    {' → '}
                    <span>{port.external}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ContainerCard = ({ container, onRemove }) => {
  const statusColors = {
    RUNNING: 'bg-green-100 text-green-800',
    STOPPED: 'bg-yellow-100 text-yellow-800',
    ERROR: 'bg-red-100 text-red-800'
  };

  const baseUrl = window.location.hostname;

  return (
    <div className="border rounded-lg bg-white shadow p-4">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-medium">{container.name}</h3>
          <span className={`inline-block px-2 py-1 rounded text-sm ${statusColors[container.status]}`}>
            {container.status}
          </span>
        </div>
        <button
          onClick={() => onRemove(container.id)}
          className="text-red-600 hover:text-red-800 p-1"
          aria-label="Remove container"
        >
          <Icons.Trash />
        </button>
      </div>

      <div className="space-y-2">
        {container.services.map((service) => (
          <ServiceCard 
            key={service.service_name} 
            service={service}
            baseUrl={baseUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default ContainerCard;
